import React, { useState } from "react";
import { Button, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import Contact from "@identitybuilding/idb-react-contact";
import { useDispatch, useSelector } from "react-redux";
import { updateModules } from "../../actions";
import axios from "axios";

const ContactEdit = (props) => {
  const dispatch = useDispatch();

  let { data, modules, lang } = useSelector((state) => state.AdminReducer);

  let [contactTitle, setContactTitle] = useState(data.options.contact.title);
  let captcha_key = useSelector((state) => state.AdminReducer.captcha_key);

  const [persons, setPersons] = useState([]);
  const [facilities, setFacilities] = useState("");
  const [contactView, setContactView] = useState("");
  const [tab, setTab] = useState(0);
  let entityCopy = Object.assign({}, data);
  let modulcesCopy = [...modules];

  const handler = (action, keyword) => {
    if (action === "title") {
      entityCopy.options.contact.title = keyword;
      adminChange(keyword, action);
    } else if (action === "deleteTitle") {
      entityCopy.options.contact.title = "";
      setContactTitle("");
      adminChange("", action);
    }

    modulcesCopy[props.index].module = (
      <Contact
        CaptchaKey={captcha_key}
        title={entityCopy.options.contact.title}
        setTab={(e) => setTab(e)}
        tab={tab}
        contactView={contactView}
        setContactView={(e) => setContactView(e)}
        parts={entityCopy.menu}
        entity_id={"BE" + entityCopy.establishment_number.replace(/\./g, "")}
        services={facilities}
        setServices={(e) => setFacilities(e)}
        card_theme="main"
        id={entityCopy.vat_number.replace(/\./g, "").replace("BE", "")}
        lang={lang}
        social_media={true}
        social_media_data={entityCopy.social_media}
        contacts={persons}
        setContacts={(e) => setPersons(e)}
        entity={{
          zoomed: false,
          id: entityCopy.vat_number ? entityCopy.vat_number : "",
          type: "enterprise",
          logos: entityCopy.logos ? entityCopy.logos : [],
          enterprise_name: entityCopy.enterprise_name
            ? entityCopy.enterprise_name
            : "",
          vat_number: entityCopy.vat_number ? entityCopy.vat_number : "",
          establishment_number: entityCopy.establishment_number
            ? entityCopy.establishment_number
            : "",
          juridical_form: entityCopy.juridical_form
            ? entityCopy.juridical_form
            : "",
          address: entityCopy.address ? entityCopy.address : [],
          telephone: entityCopy.telephone ? entityCopy.telephone : "",
          cellphone: entityCopy.cellphone ? entityCopy.cellphone : [],
          fax: entityCopy.fax ? entityCopy.fax : "",
          facilities: [],
          social_media: entityCopy.social_media ? entityCopy.social_media : [],
          contacts: [],
          registration_numbers: entityCopy.registration_numbers
            ? entityCopy.registration_numbers
            : [],
          opening_hours_reveived: false,
          openinghours: entityCopy.openinghours,
          qr: `https://api.ondernemersnetwerk.be/public/business/${"BE" +
            entityCopy.establishment_number.replace(/\./g, "")}/qr.png`,
        }}
      />
    );

    dispatch(updateModules(modulcesCopy));
  };

  const adminChange = (props, action) => {
    axios
      .put(
        `https://delhaize-proxy-muizen-default-rtdb.europe-west1.firebasedatabase.app/data/data/options/contact.json`,
        {
          [action]: props,
          ...entityCopy.options.contact,
        }
      )
      .then(async (resp) => {
      });
  };

  return (
    <div className="testBlock">
      <div className="testTitle">Contact & Openinghours</div>

      <div>
        <span>Title:</span>
        <ul>
          <OsnInputText
            onChange={(e) => setContactTitle(e.target.value)}
            value={contactTitle}
            placeholder={contactTitle}
          />
          <Button
            bgColor={["cn"]}
            size="S"
            text="Change title"
            txtColor="white"
            type="main"
            onClick={() => handler("title", contactTitle)}
          />
          <span
            className="deleteButton"
            onClick={() => handler("deleteTitle", contactTitle)}
          >
            <li>Delete</li>
          </span>
        </ul>
      </div>

      <div>
        <span>Data:</span>
        <ul>
          <a
            target="_blank"
            rel="noopener nofollow noreferrer"
            href={`https://www.wijzig.be/${data.vat_number &&
              data.vat_number.replace(/\./g, "")}`}
            className="changeData"
          >
            <Button
              bgColor={["cn"]}
              size="S"
              text="Change data here"
              txtColor="white"
              type="main"
            />
          </a>
        </ul>
      </div>
    </div>
  );
};

export default ContactEdit;
